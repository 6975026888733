<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <g>
      <path
        d="M7.97,12.861c-1.777,0-3.224,1.445-3.224,3.223s1.446,3.224,3.224,3.224s3.223-1.446,3.223-3.224
        S9.747,12.861,7.97,12.861z M7.97,18.307c-1.226,0-2.224-0.997-2.224-2.224c0-1.226,0.998-2.223,2.224-2.223
        s2.223,0.997,2.223,2.223C10.193,17.31,9.196,18.307,7.97,18.307z"/>
      <path
        d="M15.315,14.572l-1.661-0.217c-0.112-0.369-0.26-0.726-0.441-1.066l1.021-1.33
        c0.137-0.179,0.138-0.427,0.002-0.606c-0.438-0.58-0.954-1.096-1.533-1.534c-0.18-0.136-0.428-0.134-0.606,0.002l-1.329,1.021
        c-0.341-0.183-0.698-0.331-1.067-0.443L9.483,8.738c-0.029-0.223-0.204-0.399-0.427-0.43c-0.74-0.104-1.429-0.104-2.168,0
        c-0.223,0.03-0.398,0.206-0.427,0.43l-0.217,1.661c-0.37,0.112-0.728,0.26-1.069,0.443L3.845,9.82
        C3.666,9.682,3.417,9.681,3.238,9.817c-0.57,0.432-1.101,0.962-1.533,1.533c-0.136,0.179-0.135,0.428,0.002,0.606l1.021,1.329
        c-0.183,0.343-0.332,0.7-0.444,1.071l-1.661,0.217c-0.223,0.029-0.399,0.204-0.43,0.428c-0.1,0.72-0.1,1.449,0,2.167
        c0.031,0.224,0.207,0.398,0.43,0.428l1.661,0.217c0.113,0.371,0.261,0.729,0.444,1.071l-1.021,1.329
        c-0.137,0.179-0.138,0.427-0.002,0.606c0.433,0.571,0.963,1.102,1.533,1.532c0.18,0.136,0.428,0.136,0.606-0.002l1.33-1.022
        c0.342,0.183,0.7,0.331,1.07,0.443l0.217,1.66c0.029,0.224,0.204,0.4,0.427,0.431c0.37,0.051,0.726,0.077,1.082,0.077
        c0.358,0,0.717-0.026,1.087-0.078c0.223-0.031,0.397-0.207,0.426-0.431l0.218-1.661c0.369-0.112,0.726-0.261,1.067-0.443
        l1.329,1.021c0.177,0.137,0.426,0.137,0.606,0.002c0.58-0.438,1.095-0.954,1.534-1.533c0.135-0.18,0.134-0.428-0.003-0.606
        l-1.021-1.33c0.182-0.34,0.329-0.696,0.441-1.065l1.661-0.217c0.224-0.029,0.399-0.204,0.431-0.428
        c0.101-0.727,0.101-1.437,0-2.169C15.716,14.777,15.539,14.601,15.315,14.572z M14.798,16.655l-1.603,0.21
        c-0.206,0.027-0.373,0.178-0.421,0.379c-0.121,0.5-0.318,0.976-0.587,1.415c-0.108,0.176-0.096,0.401,0.03,0.565l0.986,1.283
        c-0.247,0.291-0.516,0.561-0.807,0.807l-1.283-0.985c-0.164-0.125-0.389-0.138-0.566-0.029c-0.439,0.27-0.916,0.468-1.416,0.588
        c-0.201,0.049-0.352,0.216-0.378,0.422l-0.21,1.604c-0.383,0.033-0.756,0.033-1.142,0L7.192,21.31
        c-0.027-0.206-0.178-0.374-0.379-0.422c-0.5-0.119-0.977-0.317-1.418-0.588c-0.176-0.106-0.401-0.095-0.566,0.03l-1.283,0.986
        C3.257,21.072,2.985,20.8,2.739,20.51l0.985-1.282c0.126-0.164,0.138-0.39,0.03-0.566c-0.271-0.44-0.469-0.918-0.589-1.419
        c-0.048-0.201-0.216-0.352-0.421-0.379l-1.603-0.21c-0.031-0.379-0.031-0.761,0-1.14l1.603-0.21
        c0.205-0.027,0.373-0.178,0.421-0.379c0.12-0.501,0.318-0.979,0.589-1.42c0.108-0.177,0.096-0.402-0.03-0.566l-0.985-1.282
        c0.246-0.289,0.518-0.562,0.807-0.807l1.283,0.986c0.165,0.127,0.39,0.138,0.565,0.03c0.442-0.27,0.919-0.468,1.419-0.587
        c0.201-0.048,0.352-0.216,0.379-0.421l0.209-1.603c0.384-0.033,0.757-0.033,1.142,0l0.21,1.604
        c0.027,0.205,0.177,0.373,0.378,0.421c0.5,0.121,0.976,0.318,1.416,0.588c0.177,0.108,0.402,0.097,0.566-0.03l1.283-0.986
        c0.291,0.246,0.56,0.516,0.807,0.807l-0.986,1.283c-0.126,0.164-0.138,0.389-0.03,0.565c0.27,0.441,0.467,0.917,0.587,1.416
        c0.048,0.201,0.216,0.352,0.421,0.379l1.603,0.21C14.831,15.898,14.831,16.272,14.798,16.655z"/>
      <path
        d="M18.239,3.464c-1.251,0-2.269,1.018-2.269,2.269s1.018,2.269,2.269,2.269s2.27-1.018,2.27-2.269
        S19.49,3.464,18.239,3.464z M18.239,7.002c-0.699,0-1.269-0.569-1.269-1.269s0.569-1.269,1.269-1.269s1.27,0.569,1.27,1.269
        S18.939,7.002,18.239,7.002z"/>
      <path
        d="M23.629,6.611l-0.974-0.556c0.017-0.227,0.016-0.454-0.002-0.68l0.97-0.564
        c0.194-0.113,0.289-0.343,0.231-0.561c-0.138-0.519-0.347-1.017-0.619-1.479c-0.115-0.194-0.343-0.285-0.562-0.228L21.591,2.84
        c-0.149-0.173-0.311-0.333-0.483-0.48l0.286-1.084c0.058-0.217-0.037-0.446-0.232-0.56c-0.47-0.273-0.955-0.473-1.483-0.608
        C19.46,0.05,19.232,0.149,19.12,0.345l-0.555,0.973c-0.227-0.016-0.454-0.015-0.684,0.003l-0.563-0.97
        c-0.114-0.194-0.34-0.29-0.561-0.232c-0.511,0.135-1.022,0.35-1.479,0.62c-0.193,0.115-0.287,0.345-0.228,0.562l0.295,1.082
        c-0.173,0.149-0.334,0.311-0.481,0.485L13.781,2.58c-0.222-0.059-0.447,0.038-0.561,0.232c-0.27,0.464-0.474,0.963-0.607,1.483
        c-0.056,0.218,0.041,0.447,0.236,0.559l0.974,0.556c-0.017,0.228-0.016,0.456,0.003,0.684l-0.968,0.564
        c-0.194,0.113-0.29,0.342-0.232,0.56c0.135,0.512,0.35,1.024,0.62,1.479c0.115,0.194,0.344,0.284,0.562,0.227l1.082-0.296
        c0.149,0.173,0.312,0.334,0.485,0.481l-0.287,1.083c-0.058,0.218,0.038,0.448,0.232,0.561c0.473,0.273,0.958,0.472,1.484,0.606
        c0.041,0.011,0.083,0.016,0.124,0.016c0.177,0,0.344-0.094,0.435-0.252l0.556-0.974c0.227,0.015,0.454,0.015,0.681-0.003
        l0.563,0.969c0.114,0.195,0.349,0.29,0.561,0.232c0.521-0.138,1.019-0.347,1.479-0.62c0.193-0.115,0.287-0.345,0.228-0.562
        l-0.296-1.083c0.172-0.149,0.333-0.31,0.479-0.483l1.083,0.287c0.223,0.058,0.448-0.038,0.561-0.232
        c0.273-0.472,0.473-0.958,0.607-1.484C23.921,6.951,23.824,6.722,23.629,6.611z M22.57,7.818l-1.019-0.27
        c-0.201-0.052-0.412,0.022-0.534,0.191c-0.206,0.287-0.458,0.541-0.748,0.754c-0.167,0.123-0.241,0.335-0.187,0.535l0.278,1.018
        c-0.173,0.085-0.351,0.159-0.533,0.223l-0.529-0.911c-0.104-0.178-0.308-0.273-0.513-0.242c-0.354,0.058-0.712,0.061-1.062,0.005
        c-0.204-0.033-0.408,0.066-0.511,0.246l-0.522,0.916c-0.184-0.062-0.359-0.134-0.534-0.219l0.27-1.018
        c0.053-0.201-0.023-0.413-0.191-0.534c-0.289-0.207-0.543-0.459-0.756-0.75c-0.122-0.167-0.333-0.239-0.535-0.187l-1.017,0.278
        c-0.085-0.172-0.159-0.351-0.223-0.532l0.91-0.53c0.179-0.104,0.274-0.308,0.241-0.512c-0.057-0.354-0.059-0.713-0.005-1.067
        c0.031-0.205-0.066-0.407-0.246-0.509L13.69,4.182c0.062-0.182,0.135-0.36,0.219-0.534l1.018,0.269
        c0.201,0.052,0.413-0.023,0.533-0.191c0.209-0.29,0.462-0.544,0.751-0.756c0.167-0.123,0.241-0.335,0.187-0.535L16.12,1.419
        c0.173-0.084,0.351-0.159,0.532-0.223l0.529,0.911c0.104,0.179,0.305,0.278,0.513,0.242c0.355-0.057,0.714-0.058,1.065-0.004
        c0.201,0.029,0.408-0.066,0.511-0.247l0.521-0.915c0.184,0.062,0.359,0.134,0.534,0.219l-0.27,1.02
        c-0.053,0.2,0.023,0.412,0.19,0.533c0.288,0.208,0.542,0.46,0.755,0.75c0.123,0.167,0.336,0.241,0.535,0.187l1.017-0.278
        c0.085,0.173,0.159,0.351,0.224,0.533l-0.912,0.53c-0.179,0.104-0.274,0.308-0.241,0.512c0.057,0.351,0.058,0.708,0.004,1.062
        c-0.031,0.204,0.066,0.407,0.246,0.509l0.915,0.522C22.728,7.467,22.655,7.643,22.57,7.818z"/>
    </g>
  </svg>
</template>
